<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Impresion Factura
            <router-link :to="{ name: 'facturacion-index' }">
              <button class="btn btn-outline-secondary px-4 rounded mr-2">Listar</button>
            </router-link>
            <router-link :to="{ name: 'facturacion-create' }">
              <button class="btn th-custom-color px-4 rounded">Nuevo</button>
            </router-link>
          </h1>
          <div class="separator-breadcrumb custom-border-top"></div>
        </div>
      </div>
    </div>
    <iframe :src="pdf"
            type="application/pdf"
            width="100%"
            height="770vh"
            style="overflow: auto;">
    </iframe>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'print',
  props: {},
  created() {
    this.downloadPDF();
  },
  data() {
    return {
      isLoading: true,
      pdf: null,
    };
  },
  methods: {
    generateURLBlob(response) {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      this.pdf = URL.createObjectURL(blob);
      this.isLoading = false;
    },
    downloadPDF() {
      const url = `/ventaservicios/pdf/factura/${atob(this.$route.params.id)}`;
      const params = {
        origin: 2,
        sin_logo: false,
        sin_importe: false,
        tipo_factura_id: 1,
      };
      axios.get(url, { params, responseType: 'blob' })
        .then((response) => {
          this.generateURLBlob(response);
        }).catch(() => {
          util.showNotify('Hubo un problema al obtener el PDF', 'error');
        });
    },
  },
};
</script>

<style scoped>

</style>
